
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://www.highcharts.com/samples/static/highslide.css);

// Variables
// @import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Hoterip
@import "HoteripSkin.min.css";
@import "skin-black.min.css";
@import "skin-black.min.css";
@import "style.css";
@import "select2.min.css";
@import "../plugins/slider/slider.css";
@import "../plugins/datatables/dataTables.min.css";